import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import {
  ReportType,
  postCoverageAnalysis,
} from "~/api/query_fns/coverage-analysis";

const generateReportAnalysis = async (
  doc_ids: string,
  reportType: string,
  additionalNotes: string,
  reportTitle?: string,
  claimNumber?: string
) => {
  const { jobId, processingStatus } = await postCoverageAnalysis({
    doc_ids,
    additional_notes: reportTitle
      ? `REPORT TITLE: ${reportTitle}\n ${additionalNotes}`
      : additionalNotes,
    report_type: reportType,
    claim_number: claimNumber,
  });

  return { jobId, processingStatus };
};

export const useGenerateReportNew = (reportType: ReportType) => {
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [uploadedDocs, setUploadedDocs] = useState<SingleDocResponseData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [reportTitle, setReportTitle] = useState("");
  const [claimNumber, setClaimNumber] = useState("");

  const handleUploadComplete = (docResponse: SingleDocResponseData) => {
    setUploadedDocs((prevDocs) => {
      return [...prevDocs, docResponse];
    });
  };

  const handleGenerateReport = async () => {
    if (
      (reportType === "policy_fact" && areBothTypesUploaded()) ||
      (reportType === "policies_compare" && areTwoPoliciesUploaded()) ||
      (reportType === "tower_analysis" && areTwoPoliciesUploaded()) ||
      (reportType === "generic_report" &&
        (atleastOneDoc() || additionalNotes.trim()))
    ) {
      const docIds = uploadedDocs.map((doc) => doc.document.id).join("_");
      const { jobId } = await generateReportAnalysis(
        docIds,
        reportType,
        additionalNotes,
        reportTitle,
        claimNumber
      );
      setIsLoading(true);
      navigate(`/report/analysis/${jobId}`);
    } else {
      let alertMessage = "Report cannot be generated. ";
      if (reportType === "policy_fact" && !areBothTypesUploaded()) {
        alertMessage += "Both policy and fact documents need to be uploaded.";
      } else if (
        reportType === "policies_compare" &&
        !areTwoPoliciesUploaded()
      ) {
        alertMessage += "At least two policy documents need to be uploaded.";
      } else if (
        reportType === "generic_report" &&
        (!atleastOneDoc() || !additionalNotes.trim())
      ) {
        alertMessage +=
          "At least one document needs to be uploaded or additional notes must not be empty.";
      }
      alert(alertMessage);
    }
  };

  const areBothTypesUploaded = () => {
    return (
      uploadedDocs.some((doc) => doc.document.documentType === "fact") &&
      uploadedDocs.some((doc) => doc.document.documentType === "policy")
    );
  };

  const atleastOneDoc = () => {
    return uploadedDocs.length > 0;
  };

  const areTwoPoliciesUploaded = () => {
    return (
      uploadedDocs.filter((doc) => doc.document.documentType === "policy")
        .length >= 2
    );
  };

  const isButtonDisabled = () => {
    return !(reportType === "policy_fact"
      ? areBothTypesUploaded()
      : reportType === "policies_compare"
      ? areTwoPoliciesUploaded()
      : atleastOneDoc());
  };

  return {
    isLoading,
    handleGenerateReport,
    isButtonDisabled,
    additionalNotes,
    setAdditionalNotes,
    uploadedDocs,
    handleUploadComplete,
    reportTitle,
    setReportTitle,
    claimNumber,
    setClaimNumber,
  };
};
