import React, { useState } from "react";
import { Worker } from "@react-pdf-viewer/core";
import { MilkdownProvider } from "@milkdown/react";
import { StrictMode } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from "~/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/components/ui/tabs";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "~/components/ui/accordion";
import { Input } from "~/components/ui/input";
import { CoverageReportEditor } from "./CoverageReportEditor";
import MainDocument from "~/routes/GenPrecedentSearch/MainDocument";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { openReferenceType } from "~/doc-util";
import { Button as ShadcnButton } from "~/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";
import { MoreHorizontal } from "lucide-react";
import { ReportMenuItem } from "./ReportMenu";
import { Message } from "../components/CustomMessages";
import { Rating } from "react-simple-star-rating";
import { useNavigate } from "react-router-dom";
import { regenerateWithAdditions } from "~/api/query_fns/coverage-analysis";
import { updateReport } from "~/api/query_fns/coverage-analysis";
import toast from "react-hot-toast";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "~/components/ui/dialog";
import { Textarea } from "~/components/ui/textarea";
import { Button } from "~/components/ui/button";
import AdditionalInformation from "./AdditionalInformation";

export function ReportTabs({
  reportId,
  reportName,
  fullCoverageReport,
  docs,
  openReference,
  reportChatMessages,
  documentNames,
  initialRating = 0,
  version = 0,
}: {
  reportId?: string;
  reportName: string;
  fullCoverageReport: string;
  docs: SingleDocResponseData[];
  openReference: openReferenceType;
  reportChatMessages: Message[];
  documentNames?: string[];
  initialRating?: number;
  version?: number;
}) {
  console.log(version);
  const [editableReportName, setEditableReportName] = useState(reportName);
  const [isEditing, setIsEditing] = useState(false);
  const [rating, setRating] = useState(initialRating);
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [showRegenerateDialog, setShowRegenerateDialog] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const navigate = useNavigate();
  const [showAddFilesDialog, setAddFilesDialogOpen] = useState(false);
  const [newDocumentIds, setNewDocumentIds] = useState<string[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const ratingLabels = ["INCORRECT", "BAD", "OK", "GOOD", "GREAT"];

  const handleDoubleClick = () => setIsEditing(true);
  const handleBlur = () => setIsEditing(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditableReportName(e.target.value);
    updateReport({
      id: reportId || "",
      report_name: e.target.value,
    });
  };

  const handleRating = async (rate: number) => {
    if (rate !== rating && reportId) {
      setRating(rate);

      try {
        const response = await updateReport({
          id: reportId,
          rating: rate,
        });

        if (response.success) {
          if (rate < 5) {
            setShowFeedbackDialog(true);
          } else {
            toast.success(`Thank you for your feedback`, {
              position: "top-center",
              style: {
                border: "1px solid #000000",
                padding: "16px",
                color: "#000000",
              },
              iconTheme: {
                primary: "#000000",
                secondary: "#FFFAEE",
              },
            });
          }
        } else {
          throw new Error(
            response.errors?.join(", ") || "Failed to update rating"
          );
        }
      } catch (error) {
        console.error("Error updating rating:", error);
        toast.error("Failed to update rating. Please try again later.");
      }
    } else {
      console.log("Rating unchanged or reportId missing:", { reportId, rate });
    }
  };

  const handleFeedbackSubmit = async () => {
    // Here you can add logic to submit the feedback
    console.log("Feedback submitted:", feedbackText);

    await updateReport({
      id: reportId || "",
      feedback: feedbackText,
    });

    setShowFeedbackDialog(false);
    setShowRegenerateDialog(false);

    // Show success toast
    toast.success(`Thank you for your feedback`, {
      position: "top-center",
      style: {
        border: "1px solid #000000",
        padding: "16px",
        color: "#000000",
      },
      iconTheme: {
        primary: "#000000",
        secondary: "#FFFAEE",
      },
    });
  };

  const handleRegenerateReport = async () => {
    if (!reportId) {
      console.error("Report ID is missing");
      return;
    }

    try {
      // Close the dialog first
      setShowFeedbackDialog(false);
      setShowRegenerateDialog(false);

      // Wait a short time to ensure the dialog has time to close
      await new Promise((resolve) => setTimeout(resolve, 300));

      await updateReport({
        id: reportId,
        feedback: feedbackText,
      });

      setRating(0);
      setFeedbackText("");

      const response = await regenerateWithAdditions({
        report_id: reportId,
        regenerate_prompt: feedbackText,
      });

      // Navigate after all operations are complete
      navigate(`/report/analysis/${response.jobId}?status=regen`);
    } catch (error) {
      console.error("Error regenerating report:", error);
      toast.error("Failed to regenerate report. Please try again later.");
    }
  };

  const handleSubmitAdditionalFiles = async () => {
    if (!reportId) {
      console.error("Report ID is missing");
      return;
    }

    const response = await regenerateWithAdditions({
      report_id: reportId,
      regenerate_prompt: feedbackText,
      new_document_ids: newDocumentIds.join(","),
    });

    // Use a small delay to ensure the dialog is closed before navigation
    setTimeout(() => {
      navigate(`/report/analysis/${response.jobId}?status=update`);
    }, 100);
    setAddFilesDialogOpen(false);
  };

  const handleUploadComplete = (docResponse: SingleDocResponseData) => {
    setNewDocumentIds((prev) => [...prev, docResponse.document.id]);
  };

  const handleRegenerateDialogClose = () => {
    setShowRegenerateDialog(false);
    setIsDropdownOpen(false);
  };

  const handleRegenerateDialogOpen = () => {
    setShowRegenerateDialog(true);
    setIsDropdownOpen(false);
  };

  const handleAddFilesDialogOpen = () => {
    setAddFilesDialogOpen(true);
    setIsDropdownOpen(false);
  };

  const handleAddFilesCloseDialog = () => {
    setAddFilesDialogOpen(false);
    setIsDropdownOpen(false); // Reset the dropdown state
  };

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <Tabs
        defaultValue="coverageReport"
        className="h-full min-h-[75vh] w-full"
      >
        <div className="custom-heading-darker custom-shadcn-components-sharpe  p-3 px-4">
          {isEditing ? (
            <Input
              value={editableReportName}
              onChange={handleChange}
              onBlur={handleBlur}
              autoFocus
              className="text-xl "
            />
          ) : (
            <h2
              className="text-black-500 text-xl font-semibold"
              onDoubleClick={handleDoubleClick}
            >
              {editableReportName || "Report Name - Empty"}
            </h2>
          )}

          <div className="flex items-center">
            <TabsList className="mt-5 ">
              <TabsTrigger value="coverageReport">Report</TabsTrigger>
              <TabsTrigger value="docs">Documents</TabsTrigger>
            </TabsList>
            <div className="ml-auto flex flex-col items-start" />
            <div className="ml-auto flex flex-col items-start" />
            <div className="ml-auto flex flex-col items-start" />
            <div className="ml-auto flex flex-col items-start" />

            <div className="ml-auto flex flex-col items-start">
              <div className="flex items-center">
                <span className="mr-1 mt-1 text-sm font-medium">
                  {rating > 5 ? ratingLabels[rating - 1] : "Report Feedback:"}
                </span>
                <Rating
                  tooltipDefaultText="CLICK TO RATE"
                  onClick={handleRating}
                  initialValue={rating}
                  size={20}
                  SVGclassName={`inline-block`}
                  allowFraction={false}
                  allowTitleTag={true}
                  tooltipArray={ratingLabels}
                  tooltipStyle={{
                    backgroundColor: "#e0e0e0",
                    color: "#333",
                    padding: "5px 10px",
                    borderRadius: "5px",
                  }}
                  fillColor="black"
                  emptyColor="lightgray"
                  allowHover={rating === 0}
                />
              </div>
              <div className="mt-1 text-xs text-gray-500">
                {rating > 0
                  ? "Thank You For Feedback!"
                  : "Rate & Improve This Report."}
              </div>
            </div>

            {reportId && (
              <DropdownMenu
                open={isDropdownOpen}
                onOpenChange={setIsDropdownOpen}
              >
                <DropdownMenuTrigger asChild>
                  <ShadcnButton variant="outline" className="ml-auto">
                    <MoreHorizontal className="h-4 w-4" />
                  </ShadcnButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <ReportMenuItem
                    reportId={reportId}
                    markdownContent={fullCoverageReport}
                    reportChatMessages={reportChatMessages}
                    reportTitle={reportName}
                    documentNames={documentNames}
                    onRegenerateClick={handleRegenerateDialogOpen}
                    onAddFiles={handleAddFilesDialogOpen}
                  />
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        </div>

        <TabsContent
          className="custom-shadcn-components-sharpe h-[75vh] w-full overflow-y-auto"
          value="coverageReport"
        >
          <div className="w-full">
            <StrictMode>
              <MilkdownProvider>
                <div className="flex w-full flex-col overflow-scroll">
                  <CoverageReportEditor
                    markdown={fullCoverageReport}
                    openReference={openReference}
                  />
                </div>
              </MilkdownProvider>
            </StrictMode>
          </div>
        </TabsContent>
        <TabsContent
          value="docs"
          className="custom-heading-darker custom-shadcn-components-sharpe h-[75vh] w-full overflow-y-auto"
        >
          <div className="w-full p-4">
            {docs.length > 1 ? (
              <DocumentAccordion docs={docs} />
            ) : (
              <DocumentCard doc={docs[0]} />
            )}
          </div>
        </TabsContent>
      </Tabs>

      <Dialog open={showFeedbackDialog} onOpenChange={setShowFeedbackDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Report Feedback</DialogTitle>
            <DialogDescription>
              How can we improve our report to better meet your needs?
            </DialogDescription>
          </DialogHeader>
          <Textarea
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
            placeholder="Type in your suggestions for improving this report."
          />
          <DialogFooter>
            <Button
              onClick={handleFeedbackSubmit}
              className="border border-black bg-white text-black hover:bg-black hover:text-white"
            >
              Submit Feedback
            </Button>
            <Button
              onClick={handleRegenerateReport}
              className="border border-black bg-white text-black hover:bg-black hover:text-white"
            >
              Re-generate Report
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showRegenerateDialog}
        onOpenChange={handleRegenerateDialogClose}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Re-Generate Report</DialogTitle>
            <DialogDescription>
              How can we improve our report to better meet your needs?
            </DialogDescription>
          </DialogHeader>
          <Textarea
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
            placeholder="Type in your suggestions for improving this report."
          />
          <DialogFooter>
            <Button
              onClick={() => setShowRegenerateDialog(false)}
              className="border border-black bg-white text-black hover:bg-black hover:text-white"
            >
              Cancel
            </Button>
            <Button
              onClick={handleRegenerateReport}
              className="border border-black bg-white text-black hover:bg-black hover:text-white"
            >
              Re-generate Report
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showAddFilesDialog}
        onOpenChange={(open) => {
          setAddFilesDialogOpen(open);
          if (!open) setIsDropdownOpen(false); // Reset dropdown state when dialog closes
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Upload Additional File(s)</DialogTitle>
          </DialogHeader>
          <AdditionalInformation
            additionalNotes={feedbackText}
            setAdditionalNotes={setFeedbackText}
            handleUploadComplete={handleUploadComplete}
          />
          <DialogFooter>
            <div className="mt-4 flex justify-end space-x-2">
              <Button variant="outline" onClick={handleAddFilesCloseDialog}>
                Cancel
              </Button>
              <Button onClick={handleSubmitAdditionalFiles}>
                Update Analysis
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </Worker>
  );
}

interface DocumentAccordionProps {
  docs: SingleDocResponseData[];
}

interface DocumentCardProps {
  doc: SingleDocResponseData;
}

const DocumentCard: React.FC<DocumentCardProps> = ({ doc }) => (
  <Card>
    <CardHeader>
      <CardDescription>{`Filename: ${doc.document.filename}`}</CardDescription>
    </CardHeader>
    <CardContent>
      <MainDocument
        presignedUrl={doc.presignedUrl}
        persistHighlight={() => null}
        hideDocument={false}
        highlightedContent={""}
      />
    </CardContent>
  </Card>
);

const DocumentAccordion: React.FC<DocumentAccordionProps> = ({ docs }) => (
  <Accordion type="single" collapsible className="overflow-scroll">
    {docs.map((doc, index) => (
      <AccordionItem key={index} value={`item-${index}`}>
        <AccordionTrigger>
          <CardDescription>{`Filename: ${doc.document.filename}`}</CardDescription>
        </AccordionTrigger>
        <AccordionContent>
          <MainDocument
            presignedUrl={doc.presignedUrl}
            persistHighlight={() => null}
            hideDocument={false}
            highlightedContent={""}
          />
        </AccordionContent>
      </AccordionItem>
    ))}
  </Accordion>
);
