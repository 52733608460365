import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Card, CardHeader, CardContent } from "~/components/ui/card";
import { Slider } from "~/components/ui/slider";
import { Label } from "~/components/ui/label";

interface InsuranceTowerChartProps {
  maxCoverage?: number;
}

const InsuranceTowerChart: React.FC<InsuranceTowerChartProps> = ({
  maxCoverage = 100,
}) => {
  const [data, setData] = useState(
    [
      {
        name: "ZAIC",
        fullName: "Zurich American Insurance Company - Primary",
        coverage: 32.5,
        start: 0,
        end: 32.5,
      },
      {
        name: "SRCSEIC",
        fullName:
          "Swiss Re Corporate Solutions Elite Insurance Corporation - Primary Excess",
        coverage: 25,
        start: 32.5,
        end: 57.5,
      },
      {
        name: "AIGSI",
        fullName: "AIG Specialty Insurance Company - 1st Excess",
        coverage: 20,
        start: 57.5,
        end: 77.5,
      },
      {
        name: "FFIC",
        fullName: "Fireman's Fund Indemnity Corporation - 2nd Excess",
        coverage: 10,
        start: 77.5,
        end: 87.5,
      },
      {
        name: "PESLIC",
        fullName:
          "The Princeton Excess and Surplus Lines Insurance Company - 3rd Excess",
        coverage: 12.5,
        start: 87.5,
        end: 100,
      },
    ].sort((a, b) => a.start - b.start)
  );

  const [lossAmount, setLossAmount] = useState(0);

  useEffect(() => {
    const newData = data.map((layer) => {
      if (lossAmount > layer.start) {
        const coverageUsed = Math.min(
          layer.coverage,
          Math.max(0, lossAmount - layer.start)
        );
        return { ...layer, coverageUsed };
      }
      return { ...layer, coverageUsed: 0 };
    });
    setData(newData);
  }, [lossAmount]);

  const handleSliderChange = (value: number[]) => {
    setLossAmount(Math.min(value[0], maxCoverage));
  };

  const renderCustomXAxisTick = ({ x, y, payload }: any) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
          fontWeight="bold"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <Card className="mx-auto w-full max-w-3xl">
      <CardHeader>
        <h2 className="text-2xl font-bold">Insurance Tower Analysis</h2>
        <p className="text-sm text-muted-foreground">
          Max Coverage: ${maxCoverage} million
        </p>
      </CardHeader>
      <CardContent>
        <div className="mb-6 space-y-4">
          <div className="space-y-2">
            <Label htmlFor="loss-amount">
              Simulated Loss: ${lossAmount.toFixed(2)} million
            </Label>
            <Slider
              id="loss-amount"
              defaultValue={[0]}
              max={maxCoverage}
              step={0.1}
              onValueChange={handleSliderChange}
            />
          </div>
        </div>
        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 100 }}
          >
            <XAxis
              dataKey="name"
              interval={0}
              tick={renderCustomXAxisTick}
              height={100}
            />
            <YAxis
              label={{
                value: "Coverage ($ millions)",
                angle: -90,
                position: "insideLeft",
                style: { fontWeight: "bold" },
              }}
            />
            <Tooltip
              formatter={(value) => `$${(value as number).toFixed(2)} million`}
              labelFormatter={(label) => {
                const company = data.find((item) => item.name === label);
                return company ? company.fullName : label;
              }}
            />
            <Legend />
            <Bar
              dataKey="coverage"
              stackId="a"
              name="Total Coverage"
              fill="url(#totalCoverageGradient)"
            />
            <Bar
              dataKey="coverageUsed"
              stackId="a"
              name="Coverage Used"
              fill="url(#coverageUsedGradient)"
            />
            <defs>
              <linearGradient
                id="totalCoverageGradient"
                x1="0"
                y1="0"
                x2="1"
                y2="0"
              >
                <stop offset="0%" stopColor="#22c55e" stopOpacity={0.8} />
                <stop offset="100%" stopColor="#22c55e" stopOpacity={0.3} />
              </linearGradient>
              <linearGradient
                id="coverageUsedGradient"
                x1="0"
                y1="0"
                x2="1"
                y2="0"
              >
                <stop offset="0%" stopColor="#f97316" stopOpacity={0.8} />
                <stop offset="100%" stopColor="#f97316" stopOpacity={0.3} />
              </linearGradient>
            </defs>
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default InsuranceTowerChart;

/*

While the term "follow-form" generally implies a close adherence to the underlying policy, there are some variations in how excess policies can be structured to follow form. Here are some of the other types of follow-form excess policies:

1. Selective Follow-Form: This type of excess policy follows the majority of the terms and conditions of the underlying policy but may selectively deviate from certain provisions. These deviations could involve specific exclusions, deductibles, or limits that are modified or added in the excess layer.

2. Combination Follow-Form: This structure involves an excess policy that follows form to one or more underlying policies but might also include its own set of independent terms and conditions. This type of policy is often used when there are multiple underlying policies with different terms, and the excess layer seeks to provide a more uniform and consistent coverage structure.

3. Excess Follow Form with Drop-Down Provisions: While a standard follow-form excess policy does not typically drop down to cover losses excluded in the primary layer, it is possible to include specific drop-down provisions. These provisions would outline specific scenarios where the excess policy would respond as if it were primary coverage, even if the underlying policy does not provide coverage for the specific peril or circumstance.

4. Follow Form Subject to a Minimum Attachment Point: This type of excess policy would follow the form of the underlying policy, but only after a minimum amount of loss has been incurred, regardless of whether the underlying policy limits have been fully exhausted. This structure helps to ensure that the excess layer is triggered only for more significant losses.

It is important to note that the specific terms and conditions of any follow-form excess policy will be outlined in the policy wording. Always carefully review the policy language to understand the precise nature of how the excess layer follows form and any potential deviations or modifications from the underlying coverage.
*/
