import { Worker } from "@react-pdf-viewer/core";
import { useQuery } from "react-query";
import { useLoaderData, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { getDocument } from "~/api";
import MainDocument from "~/routes/GenPrecedentSearch/MainDocument";
import { Heading } from "~/components/ui/heading";
import { CopilotKit, useCopilotReadable } from "@copilotkit/react-core";
import { useOpenReferenceDetails } from "../doc-util"; // Add this import
import { PDFViewerDialog } from "~/components/PDFViewerDialog";
import { CustomChat } from "~/components/util-chat";
import React from "react";
import { defer, Await } from "react-router-dom";
import { LoadingComponent } from "~/utils/util-report";
import ErrorBoundaryReport from "~/components/ErrorBoundaryReport";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { fetchDocumentTexts } from "~/utils";

interface LoaderParams {
  params: {
    id?: string;
  };
}

type genPolicyChatLoaderResponse = SingleDocResponseData; // Update the type for genPolicyChatLoaderResponse

export const loader = async ({ params }: LoaderParams) => {
  const fetchPolicyData = async (): Promise<SingleDocResponseData> => {
    let retryCount = 0;
    const maxRetries = 20;
    const retryDelay = 30000; // 30 seconds

    while (retryCount < maxRetries) {
      const doc = await getDocument({ id: params.id || "", includes: "text" });
      await fetchDocumentTexts([doc]);
      if (doc.document.text) {
        return doc;
      }
      console.log(
        `Text is not available for doc id: ${doc.document.id}, filename: ${doc.document.filename}`
      );
      await new Promise((resolve) => setTimeout(resolve, retryDelay));
      retryCount++;
    }
    throw new Error(
      `OCR processing has failed. Try again or contact support@qumis.ai`
    );
  };

  return defer({ policyData: fetchPolicyData() });
};

const getSelection = () => {
  if (window.getSelection) {
    return window.getSelection() || null;
  }

  if (document.getSelection) {
    return document.getSelection() || null;
  }
  return null;
};

const GenPolicyChat = () => {
  const { policyData } = useLoaderData() as {
    policyData: Promise<genPolicyChatLoaderResponse>;
  };

  return (
    <React.Suspense
      fallback={
        <LoadingComponent title="Loading Policy" showProgressBar={true} />
      }
    >
      <Await resolve={policyData} errorElement={<ErrorBoundaryReport />}>
        {(loadedData: genPolicyChatLoaderResponse) => (
          <PolicyChatContent data={loadedData} />
        )}
      </Await>
    </React.Suspense>
  );
};

const PolicyChatContent = ({ data }: { data: genPolicyChatLoaderResponse }) => {
  const { presignedUrl, document: currentDocument } = data;

  const [searchParams] = useSearchParams();
  const [highlighted, setHighlighted] = useState<null | string>(null);

  const {
    isDocViewerOpen,
    initialPage,
    document,
    setDocViewerState,
    openReference,
  } = useOpenReferenceDetails([data]); // Use the hook

  const [activeSearchResultDoc] = useState<{
    id: string;
    page: number;
    highlight?: string;
    matchType?: string;
  } | null>(null);
  const viewingActiveSearchResultDoc = !!activeSearchResultDoc;

  const searchResultDocQuery = useQuery({
    queryKey: ["document", activeSearchResultDoc?.id],
    queryFn: () => getDocument({ id: activeSearchResultDoc?.id as string }),
    enabled: !!activeSearchResultDoc,
    refetchOnWindowFocus: false,
  });

  const setHighlightedContent = () => {
    const highlightEvent = getSelection();
    const highlightedContent =
      highlightEvent?.toString()?.replace(/(\r\n|\n|\r)/gm, "") || null;
    setHighlighted(highlightedContent);
  };

  const isSearchResultDoc = !!searchResultDocQuery?.data?.document;

  let context =
    currentDocument && currentDocument.text ? currentDocument.text : "";
  context = `
  Please act as an efficient, competent, conscientious, and industrious professional assistant, who is an expert in insurance.

  Help the user achieve their goals, and you do so in a way that is as efficient as possible, without unnecessary fluff, but also without sacrificing professionalism.
  Always be polite and respectful, and prefer brevity over verbosity.

  You should take great care in referencing any part of the document with page numbers when answering questions. 
  You can ask them for clarifying questions if needed, but don't be annoying about it. If you can reasonably 'fill in the blanks' yourself, do so.

  For any phrases that reference any documents please include the filename and page number in the following format, including the square brackets: [ example.pdf, pg X].
  The reference must always be after the phrase and inline.

  Example: Reporting of actual or alleged contamination or tampering in media, specifically naming the Insured and the product [23-24 MRM Product Recall Policy.pdf, pg 6]

  Also try to be as specific as possible to include other text metadata is available, such as sections, CG number, paragraghs etc in brackets ().
  
  Remember:  
  - Always provide the response in markdown
  - Be comprehensive in your review of the full context before providing an concise answer, Create a plan before answering the question.
  - Comprehensively review every provision in all policies and documents before answering the question
  - Any reference must always be after the phrase, inline in the following format [ example.pdf, pg X]
  - If the question concerns any definition, endorsements, exclusions, or coverages in the policy, ALWAYS provide the original clause, then the en if applicable, and its implications in detail.
  - If the question references any endorsements also state type of modification append, deletion, change, replace and provide the original context and modified text for clear understanding. 
    - For example: 
     Q: What is a temp worker
     A: Based on the policy, a "temporary worker" is defined as: "a person who is: a. furnished to you to substitute for a permanent "employee"; b. a short-term worker; or c. not an "employee" or "volunteer worker"." [ example.pdf, pg X] This is an additional to the original clause
  
  - ***IMPORTANT*** Any references must always be after the phrase, inline in the following format [ example.pdf, pg X]
  The following documents contains contents related to the Insurance Policy. 

  
  Filename: policy.pdf
  Content:
  ${context}


`;

  useCopilotReadable({
    description: "all documentations",
    value: context,
  });

  const gotoPage = searchParams.get("page");

  return (
    <CopilotKit url={`${import.meta.env.VITE_COPILOT_API_URL}/api/copilot`}>
      <CustomChat
        context={context}
        initialMessage={`Hi 👋 - If you have any further questions about this policy, please let us know below.
          \n For example, you might want to ask:
          \n - What are the coverages of this policy?
          \n - What are the endorsements of this policy?
          \n - Does this policy have a professional liability exclusion
          \n - What are the premiums of this policy
          \n - Are there any notification restrictions?
          \n - Are there any exclusions in this policy related to contractual liability for media
          `}
        documents={[data]}
        reportKey={`${currentDocument.id}_policy_question`}
        openReference={openReference}
        reportSource="auth_ui_policy"
      >
        <div className="w-[42vw] ">
          <PDFViewerDialog
            open={isDocViewerOpen}
            doc={document}
            initialPage={initialPage}
            setDocViewerState={setDocViewerState}
          />

          <div className="mx-auto w-full ">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <div className=" ">
                <div className="custom-shadcn-components  mb-2">
                  <div className="custom-heading flex flex-row items-center gap-2 p-4">
                    <Heading
                      variant="h1"
                      className="text-black-100 mt-1 break-words"
                    >
                      {isSearchResultDoc
                        ? searchResultDocQuery?.data?.document.filename
                        : currentDocument.filename}
                    </Heading>
                  </div>
                </div>

                <div className="custom-shadcn-components mx-auto h-[75vh] w-full max-w-[90vw] overflow-y-auto">
                  <MainDocument
                    presignedUrl={presignedUrl}
                    persistHighlight={setHighlightedContent}
                    hideDocument={viewingActiveSearchResultDoc}
                    highlightedContent={
                      activeSearchResultDoc ? "" : highlighted
                    }
                    initialPage={gotoPage ? parseInt(gotoPage, 10) : undefined}
                  />
                </div>
              </div>
            </Worker>
          </div>
        </div>
      </CustomChat>
    </CopilotKit>
  );
};

export default GenPolicyChat;
