import { apiFn } from "~/api/utils";
import { DocumentType } from "~/api/query_fns/documents";
import { ReportSourceType } from "~/doc-util";
import { fetchDocumentTexts } from "~/utils";

export enum JobProcessingStatus {
  NotStarted = "not_started",
  OcrProcessing = "ocr_processing",
  ReportInterpreter = "report_interpreter",
  GenerateReport = "generate_report",
  SendReport = "send_report",
  Failed = "failed",
  Complete = "complete",
  Deleted = "deleted",
  Exempt = "exempt",
  Superceded = "superseded",
}

export interface CoverageAnalysisResponse {
  message: string;
  jobId: string;
  processingStatus: JobProcessingStatus;
}

export interface CoverageAnalysisParams {
  report_type: string;
  documents: DocumentType[];
  additional_notes: string;
}

export interface GetAndPostCoverageAnalysisParams {
  doc_ids: string; // doc_ids_array.map(id => id).join('_'); must be string
  report_type: string;
  additional_notes?: string;
  claim_number?: string;
}

export interface CoverageAnalysisResponse {
  reportKey: string;
  fullCoverageReport: string;
}

export interface ReportDocument {
  id: string;
  filename: string;
}

export type ReportType =
  | "policy_fact"
  | "policies_compare"
  | "policy_question"
  | "generic_report"
  | "tower_analysis";

export interface ReportJob {
  jobId: string;
  reportName: string;
  reportType: ReportType;
  documents: ReportDocument[];
  processingStatus: string;
  createdAt: string;
  claimNumber?: string;
}

export interface ReportsResponseData {
  jobs: ReportJob[];
  total: number;
}

interface ReportRequestParams {
  report_types: Array<
    | "policy_fact"
    | "policies_compare"
    | "policy_question"
    | "generic_report"
    | "tower_analysis"
  >;
  page?: number;
}

export const generateCoverageAnalysisLLMService = apiFn<
  CoverageAnalysisParams,
  CoverageAnalysisResponse
>(
  "POST",
  "/api/coverage-analysis",
  import.meta.env.VITE_COPILOT_API_URL,
  false
);

export const getCoverageAnalysis = apiFn<
  GetAndPostCoverageAnalysisParams,
  CoverageAnalysisResponse
>("GET", "/coverage");

export const postCoverageAnalysis = apiFn<
  GetAndPostCoverageAnalysisParams,
  CoverageAnalysisResponse
>("POST", "/coverage");

interface ErrorWithResponse<T = unknown> extends Error {
  response?: {
    json?: () => Promise<T>;
  };
}

export const fetchJobReport = async (params: FetchJobReportParams) => {
  try {
    const response = await apiFn<FetchJobReportParams, FetchJobReportResponse>(
      "GET",
      (params) => `/coverage/fetch_job_report/${params.jobId}`
    )(params);

    // Check if response.documents exists and is an array before mapping
    fetchDocumentTexts(response.documents);


    console.log(response);

    return response;
  } catch (error) {
    const err = error as ErrorWithResponse<{ message: string }>;
    if (err.response && err.response.json) {
      const parsedError = await err.response.json();
      throw new Error(parsedError.message);
    } else {
      throw err;
    }
  }
};

export const getReportsForUser = apiFn<
  ReportRequestParams,
  ReportsResponseData
>("GET", "/coverage/fetch_jobs_by_user");

export const findJobReportByEmail = apiFn<
  FindJobReportByEmailParams,
  FindJobReportByEmailResponse
>(
  "GET",
  (params) =>
    `/coverage/find_job_report_by_email?email=${params.email}&report_email_job_id=${params.report_email_job_id}`
);

export const regenerateReport = apiFn<
  { report_job_id: string },
  { message: string; job_id: string }
>(
  "GET",
  (params) => `/report/regenerate?report_job_id=${params.report_job_id}`
);

// Define the parameters interface
export interface FetchJobReportParams {
  jobId: string;
  lastChatMessageId?: string;
}

export const postChatMessage = apiFn<
  PostChatMessageParams,
  PostChatMessageResponse
>("POST", "/chat_messages");

export interface FetchJobReportResponse {
  id: string;
  from: string;
  reportName: string;
  reportKey: string;
  reportType: string;
  documents: Array<{
    document: DocumentType;
    presignedUrl: string;
  }>;
  coverageReport: string;
  followOnQuestions?: string[];
  processingStatus: JobProcessingStatus;
  additionalContent?: string;
  latestVersionReportId?: string;
  latestVersion?: number;
  version?: number;
  chatMessages?: {
    id: string;
    reportSource: string;
    message: string;
    reportId: string | null;
    reportKey: string;
    userId: string;
    documentIds: string;
    createdAt: string;
    updatedAt: string;
    rating: number | null;
    chatId: string;
    messageType: "user" | "assistant";
  }[];
}

export interface FindJobReportByEmailParams {
  email: string;
  report_email_job_id: string;
}

export interface FindJobReportByEmailResponse {
  userId: string;
  unlock: boolean;
}

export interface PostChatMessageParams {
  id: string;
  report_source: string; //ReportSourceType
  message: string;
  report_id?: string;
  document_ids: string;
  user_id?: string;
  report_key: string;
  chat_id: string;
  message_type: "user" | "assistant";
}

export interface PostChatMessageResponse {
  id: string;
  report_key: string;
  report_source: ReportSourceType;
  message: string;
  report_id: string;
  document_ids: string;
  user_id: string;
  created_at: string;
  updated_at: string;
}

export interface UpdateMessageRatingParams {
  id: string;
  rating: number;
}

export interface UpdateMessageRatingResponse {
  rating: number;
  id: string;
  reportSource: string;
  message: string;
  reportId: string | null;
  reportKey: string;
  userId: string;
  documentIds: string;
  createdAt: string;
  updatedAt: string;
  chatId: string;
  messageType: "user" | "assistant";
}

export const updateMessageRating = apiFn<
  UpdateMessageRatingParams,
  UpdateMessageRatingResponse
>("PATCH", (params) => `/chat_messages/${params.id}/rating`);

export interface RegenerateWithAdditionsParams {
  report_id: string;
  regenerate_prompt: string;
  new_document_ids?: string; // doc_ids_array.map(id => id).join('_'); must be string
}

export interface RegenerateWithAdditionsResponse {
  message: string;
  jobId: string;
}

export const regenerateWithAdditions = apiFn<
  RegenerateWithAdditionsParams,
  RegenerateWithAdditionsResponse
>("POST", "/coverage/regenerate/");
export interface UpdateReportParams {
  id: string;
  report_name?: string;
  feedback?: string;
  rating?: number;
}

export interface UpdateReportResponse {
  success: boolean;
  message?: string;
  errors?: string[];
}

export const updateReport = apiFn<UpdateReportParams, UpdateReportResponse>(
  "PATCH",
  (params) => `/coverage/update_report/${params.id}`
);
