import { Link, useNavigate } from "react-router-dom";
import useSession from "~/hooks/useSession";
import { useMutation } from "react-query";
import { destroySession } from "~/api";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "~/components/ui/dropdown-menu";
import {
  PersonIcon,
  GlobeIcon,
  ExitIcon,
  GearIcon,
  EnvelopeClosedIcon,
  QuestionMarkCircledIcon,
} from "@radix-ui/react-icons";

const UserDropdown = () => {
  const { loggedIn, sessionQuery, session } = useSession();
  const navigate = useNavigate();
  const role = session?.role;
  const email = session?.email;

  const { mutate } = useMutation(destroySession, {
    onSuccess: async () => {
      await sessionQuery.refetch();
      navigate("/");
    },
  });

  // making typescript happy
  if (!role || !loggedIn || !email) return <></>;

  return (
    <DropdownMenu>

      <DropdownMenuTrigger asChild>
        <div className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full !bg-black text-lg text-white">
          {email.charAt(0).toUpperCase()}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
      <DropdownMenuItem asChild>
        <a href="https://help.qumis.ai/" target="_blank" rel="noopener noreferrer" className="w-full cursor-pointer">
          <QuestionMarkCircledIcon className="mr-2 h-4 w-4" />
          Help Center
        </a>
      </DropdownMenuItem>
        {["site_admin", "admin", "owner"].includes(role) && (
          <DropdownMenuItem asChild>
            <Link to="/admin/members" className="w-full cursor-pointer">
              <PersonIcon className="mr-2 h-4 w-4" />
              Members
            </Link>
          </DropdownMenuItem>
        )}
        {role === "site_admin" && (
          <DropdownMenuItem asChild>
            <Link to="/admin/organizations" className="w-full cursor-pointer">
              <GlobeIcon className="mr-2 h-4 w-4" />
              Organizations
            </Link>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem asChild>
          <Link to="/account_settings" className="w-full cursor-pointer">
            <GearIcon className="mr-2 h-4 w-4" />
            Settings
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem className="cursor-pointer">
          <EnvelopeClosedIcon className="mr-2 h-4 w-4" />
          <a
            href="mailto:support@qumis.ai"
            className="flex w-full items-center"
          >
            support@qumis.ai
          </a>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => mutate()} className="cursor-pointer">
          <ExitIcon className="mr-2 h-4 w-4" />
          Log Out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default UserDropdown;
