import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Card, CardHeader, CardContent } from "~/components/ui/card";
import { Button } from "~/components/ui/button";

export interface InsurancePolicyComparisonChartProps {
  initialChartType?: "grouped" | "stacked";
}

const policyData = [
  { category: "Property", "Oswald-Amerisure": 14766, "VTC-Insurance": 11615 },
  {
    category: "General Liability",
    "Oswald-Amerisure": 245086,
    "VTC-Insurance": 174126,
  },
  {
    category: "Auto Liability",
    "Oswald-Amerisure": 371541,
    "VTC-Insurance": 204153,
  },
  {
    category: "Workers' Compensation",
    "Oswald-Amerisure": 121378,
    "VTC-Insurance": 99758,
  },
  {
    category: "Umbrella Liability",
    "Oswald-Amerisure": 69728,
    "VTC-Insurance": 107788,
  },
  {
    category: "Excess Liability",
    "Oswald-Amerisure": 169500,
    "VTC-Insurance": 25520,
  },
  {
    category: "Cyber Liability",
    "Oswald-Amerisure": 18826,
    "VTC-Insurance": 14964,
  },
  {
    category: "Management Liability",
    "Oswald-Amerisure": 17246,
    "VTC-Insurance": 13741,
  },
  {
    category: "Inland Marine",
    "Oswald-Amerisure": 4303,
    "VTC-Insurance": 5155,
  },
];

const InsurancePolicyComparisonChart: React.FC<{
  initialChartType: "grouped" | "stacked";
}> = ({ initialChartType }) => {
  console.log(
    "InsurancePolicyComparisonChart rendered with initialChartType:",
    initialChartType
  );
  const [chartType, setChartType] = useState<"grouped" | "stacked">(
    initialChartType
  );

  const formatCurrency = (value: number): string => {
    return `$${value.toLocaleString()}`;
  };

  return (
    <Card className="mx-auto w-full max-w-4xl">
      <CardHeader>
        <h2 className="text-2xl font-bold">
          Insurance Policy Premium Comparison
        </h2>
        <div className="flex space-x-2">
          <Button
            onClick={() => setChartType("grouped")}
            variant={chartType === "grouped" ? "default" : "outline"}
          >
            Grouped
          </Button>
          <Button
            onClick={() => setChartType("stacked")}
            variant={chartType === "stacked" ? "default" : "outline"}
          >
            Stacked
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            data={policyData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" />
            <YAxis tickFormatter={formatCurrency} />
            <Tooltip
              formatter={(value: number | string) => [
                formatCurrency(Number(value)),
                "",
              ]}
            />
            <Legend />
            <Bar
              dataKey="Oswald-Amerisure"
              fill="#8884D8"
              stackId={chartType === "stacked" ? "a" : undefined}
            />
            <Bar
              dataKey="VTC-Insurance"
              fill="#82CA9D"
              stackId={chartType === "stacked" ? "a" : undefined}
            />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default InsurancePolicyComparisonChart;

/*
Based on the policies reviewed, here's an array of JSON objects scoring each type of coverage. Please note that these scores are subjective interpretations based on the available information, with 100 being the best possible coverage. The scores reflect factors like coverage limits, retention amounts, and the breadth of protection offered.

const data = [
  {
    subject: 'Cyber Liability',
    'Policy A': 85,
    'Policy B': 95,
    fullMark: 100,

*/
